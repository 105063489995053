import { getMenus } from "../api/general";

async function ProtectedRoutes(url) {
  const menuItems = await getMenus();

  const checkUrlExists = (urlToCheck) => {
    return menuItems.some((item) => item.url === urlToCheck);
  };

  return checkUrlExists(url);
}
export default ProtectedRoutes;
