import axios from "axios";
import { RefreshToken } from "./auth";

const apiURL = process.env.REACT_APP_AUTH_API_URL;
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await RefreshToken();

      return access_token;
    }
    return Promise.reject(error);
  }
);

export async function QtyProcessedDocuments() {
  const url = apiURL + "/api/Dashboard/QtyProcesedDocuments";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    StartDate: "2022-01-01",
    EndDate: "2024-12-31",
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function AvailableCredit() {
  const url = apiURL + "/api/Dashboard/AvailableCredit";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function UsageDashboard(startDate, endDate) {
  const url = apiURL + "/api/Dashboard/UsageDashboard";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    StartDate: "2024-01-01",
    EndDate: "2024-09-17",
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}
