import axios from "axios";
import { RefreshToken } from "./auth";

const apiURL = process.env.REACT_APP_AUTH_API_URL;
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await RefreshToken();

      return access_token;
    }
    return Promise.reject(error);
  }
);

export async function changeInformation(
  names,
  surName,
  phoneCode,
  phoneNumber
) {
  const url = apiURL + "/api/General/ChangeInformation";
  const body = {
    names: names,
    surName: surName,
    PhoneCode: phoneCode,
    PhoneNumber: phoneNumber,
  };
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .put(url, body, { headers })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("countryCode", phoneCode);
        sessionStorage.setItem("phone", phoneNumber);
        sessionStorage.setItem("names", names);
        sessionStorage.setItem("surName", surName);
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function AllowedUploadExtensionFile() {
  const url = apiURL + "/api/General/AllowedUploadExtensionFile";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getUserInfo() {
  return await axiosApiInstance
    .get(apiURL + "/api/General/UserInfo", {
      headers: {
        Authorization: "Bearer " + sessionStorage.token,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        sessionStorage.setItem("names", response.data.names);
        sessionStorage.setItem("surNames", response.data.surNames);
        sessionStorage.setItem("UserName", response.data.userName);
        sessionStorage.setItem("email", response.data.email);
        sessionStorage.setItem("countryCode", response.data.countryCode);
        sessionStorage.setItem("phone", response.data.phone);
        sessionStorage.setItem("companyId", response.data.companyId);
        sessionStorage.setItem("company", response.data.company);
        sessionStorage.setItem(
          "roleCompanyName",
          response.data.roleCompanyName
        );
        sessionStorage.setItem("welcomeMessage", response.data.welcomeMessage);
        getConfiguration();
      }
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function getConfiguration() {
  const url = apiURL + "/api/General/GetCognitoConfiguration";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      if (response.status === 200) {
        //console.log(response);
        sessionStorage.setItem("bucketName", response.data.bucketName);
        sessionStorage.setItem("bucketRegion", response.data.bucketRegion);
        sessionStorage.setItem("identityPoolId", response.data.identityPoolId);
        sessionStorage.setItem("providerName", response.data.providerName);
        sessionStorage.setItem("poolId", response.data.poolId);
        sessionStorage.setItem("companyName", response.data.companyName);
        sessionStorage.setItem("useOpenAI", "Y");
      }
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
export async function getDocumentTypes() {
  const url = apiURL + "/api/General/GetDocumentTypes";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function userInformation() {
  if (sessionStorage.token) {
    return {
      names: sessionStorage.names,
      surNames: sessionStorage.surNames,
      email: sessionStorage.email,
      countryCode: sessionStorage.countryCode,
      phone: sessionStorage.phone,
      CompanyId: sessionStorage.CompanyId,
      company: sessionStorage.company,
      roleCompanyName: sessionStorage.roleCompanyName,
      welcomeMessage: sessionStorage.welcomeMessage,
      //display wizard
    };
  } else {
    return {};
  }
}

export async function getMenus() {
  const url = apiURL + "/api/General/GetMenus";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}
