import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Drawer from "@mui/material/Drawer";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import Logo from ".//assets/Xerox-Signature.svg";
import LogoInverted from ".//assets/Xerox-Signature-Reverse.svg";
import AvatarPlaceholder from ".//assets/avatar_placeholder_128@2x.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout } from "./api/auth";
import Avatar from "@mui/material/Avatar";
import MainButton from "./mainButton";
import { getMenus } from "./api/general";
import { useEffect } from "react";

export default function MenuAppBar() {
  let navigate = useNavigate();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subtitle, setsubtitle] = React.useState("Dashboard");
  const [sideBarMenu, setSideBarMenu] = React.useState([]);
  const [headerMenu, setHeaderMenu] = React.useState([]);
  const [secondaryMenu, setSecondaryMenu] = React.useState([]);

  useEffect(() => {
    const fetchMenus = async () => {
      const getAPIMenus = await getMenus();
      setSideBarMenu(filterByCategory(getAPIMenus, 1));
      setHeaderMenu(filterByCategory(getAPIMenus, 2));
      setSecondaryMenu(filterByCategory(getAPIMenus, 3));
      console.log(sideBarMenu);
    };

    fetchMenus();
  }, []);

  const filterByCategory = (items, categoryId) => {
    return items.filter((item) => item.menuCategoryId === categoryId);
  };

  const handleChange = (event) => {
    logout();
    navigate("/login");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = (item) => {
    setsubtitle("Profile");
    navigate("/profile");
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(false);
  };

  const list = (anchor) => (
    <Box
      elevation={0}
      sx={{
        bgcolor: "secondary.main",
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ bgcolor: "secondary.main", color: "white", pb: 3 }}>
        <ListItem
          disablePadding
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 64,
            }}
          >
            <Box
              component="img"
              sx={{
                height: 20,
                mr: 2,
              }}
              alt="xerox logo."
              src={LogoInverted}
            />
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1 }}
              display="inline"
            >
              AnyDoc
            </Typography>
          </Box>
        </ListItem>
        <ListItem
          disablePadding
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            display="block"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: 128,
            }}
          >
            <Avatar
              alt="User"
              src={AvatarPlaceholder}
              sx={{ width: 64, height: 64, margin: "auto", mb: 2, mt: 3 }}
              onClick={handleProfile}
            />

            <Typography
              component="div"
              sx={{ flexGrow: 1, mb: 3 }}
              display="block"
            >
              {sessionStorage.email}
            </Typography>
          </Box>
        </ListItem>
        <Divider variant="middle" sx={{ bgcolor: "#ffffff", mb: 3, mt: 3 }} />
        {sideBarMenu.map((item) => (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setOpen(false);
                setsubtitle(item.name);
              }}
              component={Link}
              to={item.url}
            >
              <i class="xgl-panels xglsize-24"></i>

              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* 
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setOpen(false);
              setsubtitle("Dashboard");
            }}
            component={Link}
            to="/dashboard"
          >
            <i class="xgl-panels xglsize-24"></i>

            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setOpen(false);
              setsubtitle("Pending Documents");
            }}
            component={Link}
            to="/pending"
          >
            <ListItemText primary="Pending Documents" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setOpen(false);
              setsubtitle("Completed Documents");
            }}
            component={Link}
            to="/completed"
          >
            <ListItemText primary="Completed Documents" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setOpen(false);
              setsubtitle("User Administration");
            }}
            component={Link}
            to="/userAdmin"
          >
            <ListItemText primary="User Administration" />
          </ListItemButton>
        </ListItem>
        {/*<ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setOpen(false);
              setsubtitle("Setup Documents");
            }}
            component={Link}
            to="/setup"
          >
            <ListItemText primary="Setup Documents" />
          </ListItemButton>
        </ListItem> */}
      </List>
    </Box>
  );
  if (sideBarMenu.length < 1) {
    return <></>;
  }
  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/dashboard">
            <Box
              component="img"
              sx={{
                height: 20,
                mr: 2,
              }}
              alt="xerox logo."
              src={Logo}
            />
          </Link>
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1 }}
            display="inline"
          >
            AnyDoc
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, ml: 1 }}
            display="inline"
            color="gray"
          ></Typography>

          {auth && (
            <div id="mainBar">
              {headerMenu.map((item) => (
                <MainButton></MainButton>
              ))}

              <Tooltip arrow title="Account">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="secondary"
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: 6 }}
              >
                {secondaryMenu.map((item) => (
                  <Box
                    component="span"
                    sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  >
                    <MenuItem onClick={handleProfile}>{item.name}</MenuItem>
                  </Box>
                ))}

                <MenuItem onClick={handleClose}>
                  {" "}
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={auth}
                        onChange={handleChange}
                        aria-label="login switch"
                      />
                    }
                    label={auth ? "Logout" : "Login"}
                  />
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <div>
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          hideBackdrop="true"
          elevation={0}
          PaperProps={{
            sx: {
              backgroundColor: "secondary.main",
            },
          }}
        >
          {list("left")}
        </Drawer>
      </div>
    </Box>
  );
}
