import axios from "axios";
import { RefreshToken } from "./auth";

const apiURL = process.env.REACT_APP_AUTH_API_URL;
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await RefreshToken();

      return access_token;
    }
    return Promise.reject(error);
  }
);

export async function FieldsByTemplate(id) {
  const url = apiURL + "/api/DocumentType/FieldsByTemplate/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentTypeTemplateByCompany() {
  const url = apiURL + "/api/DocumentType/GetDocumentTypeTemplateByCompany/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentTypeTemplateById(id) {
  const url = apiURL + "/api/DocumentType/GetDocumentTypeTemplateById/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetModel() {
  const url = apiURL + "/api/DocumentType/Model/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}
export async function GetAnalyzeDocumentConfiguration() {
  const url = apiURL + "/api/DocumentType/AnalyzeDocumentConfiguration/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SaveTemplate(
  id,
  templateName,
  llmTemperature,
  llmMaxTokenOutput,
  llmBasePrompt,
  llmPrompt,
  model,
  searchCriteria,
  confidence,
  ignoreFieldConfiguration,
  templateToBuildJSONString,
  analyzeConfiguration
) {
  const url = apiURL + "/api/DocumentType/SaveTemplate";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    Id: id,
    TemplateName: templateName,
    LLMTemperature: llmTemperature,
    LLMMaxTokenOutput: llmMaxTokenOutput,
    LLMBasePrompt: llmBasePrompt,
    LLMPrompt: llmPrompt,
    Model: model,
    SearchCriteria: searchCriteria,
    Confidence: confidence,
    IgnoreFieldConfiguration: ignoreFieldConfiguration,
    TemplateToBuildJSONString: templateToBuildJSONString,
    AnalyzeConfiguration: analyzeConfiguration,
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetTypeOfGrouping() {
  const url = apiURL + "/api/DocumentType/TypeOfGrouping/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDataType() {
  const url = apiURL + "/api/DocumentType/DataType/";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function SaveFieldTemplate(
  id,
  documentTypeTemplateId,
  selected,
  fieldName,
  prompt,
  typeOfGroupingId,
  parentId,
  confidence,
  dataTypeID,
  labelName
) {
  if (id === 0) {
    id = "";
    documentTypeTemplateId = "";
  }
  const url = apiURL + "/api/DocumentType/SaveFieldTemplate";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    Id: id,
    DocumentTypeTemplateId: documentTypeTemplateId,
    Selected: selected,
    FieldName: fieldName,
    Prompt: prompt,
    TypeOfGroupingId: typeOfGroupingId,
    ParentId: parentId,
    Confidence: confidence,
    DataTypeID: dataTypeID,
    MainCategory: false,
    LabelName: labelName,
    UserName: "",
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}
