import axios from "axios";
import { RefreshToken } from "./auth";

const apiURL = process.env.REACT_APP_AUTH_API_URL;
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await RefreshToken();

      return access_token;
    }
    return Promise.reject(error);
  }
);

export async function GetDocuments() {
  const url = apiURL + "/api/Document/GetDocuments";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentsCompleted() {
  const url = apiURL + "/api/Document/GetDocumentsCompleted";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      //console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentDetail(id, result) {
  const url = apiURL + "/api/Document/GetDocumentDetail/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      if (typeof response.data === typeof "String") {
        return JSON.stringify(response.data);
      }
      switch (result) {
        case "1":
          return response.data.outputResult;
        case "2":
          return response.data.previousOutput;
        case "3":
          return response.data.validatedResult;
        case "4":
          return response.data.completeAWSResults;
      }
    })
    .catch(function (error) {
      return error;
    });
}
export async function GetImageDetail(id) {
  const url = apiURL + "/api/Document/GetImageDetail/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentsPending() {
  const url = apiURL + "/api/Document/GetDocumentsPending";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function UploadDocument(
  fileName,
  fileType,
  fileTypeId,
  OgFileName
) {
  const url = apiURL + "/api/Document/UploadDocument";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    DocumentTypeId: fileTypeId,
    FileName: OgFileName,
    FilePath: fileType + "/" + fileName,
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

export async function UpdateDocumentOutput(
  id,
  validatedResult,
  reviewCompleted
) {
  const url = apiURL + "/api/Document/UpdateDocumentOutputResult";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  var body = {};
  if (reviewCompleted > 0) {
    body = {
      Id: id,
      ValidatedResult: JSON.stringify(validatedResult),
      ReviewCompleted: 1,
    };
  } else {
    body = {
      Id: id,
      ValidatedResult: JSON.stringify(validatedResult),
    };
  }

  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}

export async function GetDocumentDetailInURL(id, result) {
  const url = apiURL + "/api/Document/GetDocumentDetailInURL/" + id;
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  return await axiosApiInstance
    .get(url, { headers })
    .then(function (response) {
      return GetDocumentDetailFromURL(response.data.responseURL, result);
    })
    .catch(function (error) {
      return error;
    });
}

async function GetDocumentDetailFromURL(url, result) {
  return await axiosApiInstance
    .get(url)
    .then(function (response) {
      switch (result) {
        case "0":
          return response.data.ReviewCompleted;
        case "1":
          return response.data.OutputResult;
        case "2":
          return response.data.PreviousOutput;
        case "3":
          return response.data.ValidatedResult;
        case "4":
          return response.data.CompleteAWSResults;
      }
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}

export async function ExportInformation(id, ff) {
  const url = apiURL + "/api/Document/ExportInformation";
  const headers = {
    Authorization: "Bearer " + sessionStorage.token,
  };
  const body = {
    DocumentId: id,
    FileFormat: ff,
  };
  return await axiosApiInstance
    .post(url, body, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}
